import styled from "styled-components";

const Frame = styled.div`
    display: flex;
    padding: ${props => props.theme.spacing.medium} 8px 0px 8px;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.lmedium};
    align-self: stretch;
    border-bottom: 1px solid ${props => props.theme.color.lightGray};
    
    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        padding: ${props => props.theme.spacing.medium} var(--numberic-value-value-40, 40px) 0px var(--numberic-value-value-40, 40px);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        padding: ${props => props.theme.spacing.medium} var(--numberic-value-value-40, 40px) 0px var(--numberic-value-value-40, 40px);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        padding: var(--numberic-value-value-48, 48px) var(--numberic-value-value-24, 24px) 0px var(--numberic-value-value-24, 24px);
        align-items: flex-start;
        gap: 50px;
    }
`
const FirstContent = styled.div`
    flex-direction: column;
    width: ${props => props.theme.widths.container}; 
    align-items: flex-start;
    gap: 8px;
    display: inline;
    
    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        width: ${props => props.theme.widths.fullWidth};
    }
    
    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.fullWidth}; 
    }
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
    }
`
const Span = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard,sans-serif;
    font-size: ${props => props.theme.fontSizes.large};
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.light};
    line-height: normal;
    letter-spacing: -1.2px;

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        font-size: ${props => props.theme.fontSizes.medium};
    }
`
const SecondContent = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        width: ${props => props.theme.widths.fullWidth};
    }
    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.fullWidth};
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
        font-size: ${props => props.theme.fontSizes.medium};
        flex-direction: column;
        align-self: stretch;
        justify-content: center;
        align-items: flex-end;
    }
`
const LeftFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
    }
`
const InnerFrame1 = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.msmall};
    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.fullWidth};
        align-items: center;
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
        align-items: center;
        gap: 10px;
        text-align: center;
        
    }
`
const LogoImg = styled.img`
    width: 160px;
    height: 160px;
    filter: drop-shadow(0px 21.913px 12.174px rgba(0, 0, 0, 0.25));
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: 90px;
        height: 90px;
        margin: auto;
    }
`
const Bold2 = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard,sans-serif;
    font-size: ${props => props.theme.fontSizes.xlarge};
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.extraBold};
    line-height: 100%;
    >br {
        &:nth-child(1){
            display: none;
        }
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        font-size: ${props => props.theme.fontSizes.medium};
        text-align: center;
        width: 200px;
        flex-shrink: 0;
        //white-space: normal;
        //overflow-wrap: break-word;
        word-break: keep-all;
        >br {
            &:nth-child(1){
                display: block;
            }
        }
    }
`
const InnerFrame2 = styled.div`
    width: ${props => props.theme.widths.fullWidth};
    display: flex;
    padding: var(--numberic-value-value-24, 24px) 0;
    justify-content: center;
    align-items: flex-start;
    gap: 18px;
`

const RightFrame = styled.div`
    display: flex;
    width: 405.695px;
    height: 544px;
    padding: 3.665px 2.836px 0 3.089px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
        height: auto;
        justify-content: flex-end;
        img {
            content: url(/img/mobile.png);
        }
        margin: auto;
    }
`
const MobileIcon = styled.img`
    display: flex;
    width: 100%;
    padding: 3.665px 2.836px 0 3.089px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.fullWidth};
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: 70%;
    }
`

const Button = styled.button`
    width: ${props => props.theme.widths.fullWidth};
    display: flex;
    height: 70px;
    padding: var(--numberic-value-value-16, 16px) var(--numberic-value-value-24, 24px);
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--numberic-value-value-12, 12px);
    border: var(--numberic-value-value-0, 1px) solid var(--ui-color-line-black, #19191B);
    background: var(--ui-color-bg-white, #FFF);
    margin-bottom: 20px;
    color: var(--ui-color-text-black, #19191B);
    text-align: center;
    font-family: Pretendard,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 120%; /* 21.6px */

    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        margin-bottom: 0;
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        margin: 0 40px;
    }
`

export default function Body() {
    return (
        <>
            <Frame>
                <FirstContent>
                    <Span>
                        <div>메타로고스는</div>
                        금융, 퀀트, 테크 프로들이 모여 <br />
                        <b>공모주 간편투자 서비스 일육공</b>을 <br />
                        개발하고 운영(
                    </Span>
                    <img src="/img/ml-logo.svg" alt={'ml'}></img>
                    <Span>
                        )하고 있습니다.<br />
                    </Span>
                </FirstContent>
                <SecondContent>
                    <LeftFrame>
                        <InnerFrame1>
                            <LogoImg src="/img/160-logo.png" />
                            <Bold2>일육공<br /> 공모주<br/> 간편투자 앱</Bold2>
                        </InnerFrame1>
                        <InnerFrame2>
                            <Button style={{ cursor: 'pointer' }} onClick={() => window.open("https://160ipo.com/")}>160 자세히 보기↗</Button>
                        </InnerFrame2>
                    </LeftFrame>
                    <RightFrame>
                        <MobileIcon src="/img/mobile.png"/>
                    </RightFrame>
                </SecondContent>
            </Frame>
        </>
    )
}
